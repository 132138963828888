// http://ionicframework.com/docs/theming/
//
@import '@ionic/angular/css/core.css';
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

@import '@mobiscroll/angular/dist/css/mobiscroll.scss';
@import './theme/kendo.scss';

// Tailwind
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face {
  font-family: 'alberta-mada-medium';
  src: url('/assets/fonts/mada-medium.ttf') format('truetype');
}

@font-face {
  font-family: 'alberta-mada-semibold';
  src: url('/assets/fonts/mada-semibold.ttf') format('truetype');
}

@font-face {
  font-family: 'alberta-mada-regular';
  src: url('/assets/fonts/mada-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'alberta-mada-light';
  src: url('/assets/fonts/mada-light.ttf') format('truetype');
}

@font-face {
  font-family: 'alberta-crimson-regular';
  src: url('/assets/fonts/crimson-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'alberta-libre-baskerville-regular';
  src: url('/assets/fonts/libre-baskerville-regular.ttf') format('truetype');
}

:root {
  --alberta-color-standard: black;
  --ion-color-blue: red;
  --ion-color-blue-tint: orange;
  --ion-color-blue-contrast: #ffffff;

  // fonts
  --font-regular: 'alberta-mada-regular';
  --font-medium: 'alberta-mada-medium';
  --font-semibold: 'alberta-mada-semibold';
  --font-light: 'alberta-mada-light';
  --font-bolder: 700;

  // Primary font
  --ion-font-family: 'alberta-mada-regular';
  // icon vars
  --ion-icon-space: 57px;
  --ion-icon-size: 32px;
  // height of select-modal
  --ion-select-modal: 400px;
  // variables for search-modal
  --ion-search-modal-top: 16px;
  --ion-search-modal-right: 12px;
  --ion-serach-modal-width: 300px;
  --ion-search-modal-height: 400px;

  // variables for inputs
  --ion-input-top-padding: 10px;
  --ion-input-bottom-padding: 10px;
  --ion-input-right-padding: 47px;
  --ion-input-top-margin: 10px;
  --ion-input-text-font-size: 14px;
  --ion-input-value-font-size: 17px;

  // variables for alberta chat
  --alberta-chat-background: #eff1f3;
  --alberta-chat-message-background: white;
  --alberta-chat-message-border: #eaecf0;
  --alberta-chat-muted-text: #acacac;
  --alberta-chat-text: #2b2b2b;
  --alberta-chat-avatar-disalbed-background: #ffffff;
  --alberta-chat-avatar-disalbed-text: #959595;
  --alberta-chat-avatar-disabled-border: 1px solid var(--alberta-chat-avatar-disalbed-text);

  // alberta design spec
  --alberta-spacing: 0.5rem;
  --alberta-border-bottom: 1px solid var(--alberta-color-border);
  --alberta-border-top: 1px solid var(--alberta-color-border);
  --alberta-search-bar-height: 66px;

  // autocomplete list items
  --alberta-list-item-heading-font: 1.1em var(--font-medium);
  --alberta-list-item-default-font: 0.9em var(--font-regular);

  //variables for alberta tab control
  --alberta-tab-control-font-size: 16px;

  //variables for alberta alb therapy tree
  --alb-therapy-tree-therapy-margin: 7.5px 7.5px 7.5px 23.5px;

  //variables for select modal
  --alberta-select-modal-content-align: center;
}

.ion-color-blue {
  --ion-color-base: red;
  --ion-color-base-rgb: 0, 128, 255;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #73b9ff;
  --ion-color-tint: #73b9ff;
}

// still needed for ionic toggle and checkbox
.ion-color-variant-secondary {
  --ion-color-base: var(--alberta-color-secondary-variant);
  // RGB is needed for ion-toggle
  --ion-color-base-rgb: 38, 128, 235;
  --ion-color-contrast: var(--alberta-color-surface);
  --ion-color-contrast-rgb: 245, 245, 245;
  --ion-color-shade: var(--alberta-color-secondary-variant-shade);
  --ion-color-tint: var(--alberta-color-secondary-variant);
}

// needed for toggle at settings page
.ion-color-secondary-alberta {
  --ion-color-base: var(--alberta-color-secondary);
  // RGB is needed for ion-toggle
  --ion-color-base-rgb: 0, 179, 162;
  --ion-color-contrast: var(--alberta-color-surface);
  --ion-color-contrast-rgb: 245, 245, 245;
  --ion-color-shade: var(--alberta-color-secondary-shade);
  --ion-color-tint: var(--alberta-color-secondary);
}

.ion-color-secondary-variant-alberta {
  --ion-color-base: var(--alberta-color-secondary-variant);
  // RGB is needed for ion-toggle
  --ion-color-base-rgb: 0, 179, 162;
  --ion-color-contrast: var(--alberta-color-surface);
  --ion-color-contrast-rgb: 245, 245, 245;
  --ion-color-shade: var(--alberta-color-secondary-variant-shade);
  --ion-color-tint: var(--alberta-color-secondary-variant);
}

.ion-color-fab-primary {
  --ion-color-base: var(--alberta-color-primary);
  --ion-color-base-rgb: 105, 187, 123;
  --ion-color-contrast: var(--alberta-color-primary-contrast);
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: var(--alberta-color-primary-shade);
  --ion-color-tint: var(--alberta-color-primary);
}

.ion-color-fab-secondary {
  --ion-color-base: var(--alberta-color-secondary-contrast);
  --ion-color-contrast: var(--alberta-color-secondary);
  --ion-color-shade: var(--alberta-color-secondary);
  --ion-color-tint: var(--alberta-color-secondary);
  --background-hover-opacity: 0;
}

.ion-color-fab-secondary:hover {
  --ion-color-base: var(--alberta-color-secondary);
  --ion-color-contrast: var(--alberta-color-secondary-contrast);
  --background-hover-opacity: 0;
}

a {
  color: var(--alberta-color-secondary);
}

.bold {
  font-family: var(--font-semibold);
}

// deactivate button outline when focused
button:focus {
  outline: unset;
}

// fix notch problem at iPhone
body {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}

// header shoud be in medium
ion-title {
  font-family: 'alberta-mada-medium';
}

// edit for fab
ion-fab {
  right: calc(env(safe-area-inset-right) + 37px);
  bottom: 15px;

  .fab-button-disabled {
    opacity: 0.5;
  }
}

ion-fab > ion-fab-button > ion-icon {
  zoom: 1.5;
}

ion-fab-list > ion-fab-button {
  zoom: 1.3;

  ion-icon {
    zoom: 1.3;
  }
}

ion-fab-button[data-desc] {
  position: relative;
}

ion-fab-button[data-desc]::after {
  position: absolute;
  content: attr(data-desc);
  z-index: 1;
  right: 55px;
  bottom: 2px;
  background-color: var(--alberta-color-primary);
  padding: 9px;
  border-radius: 15px;
  color: white;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
}

// deactivate focused effect for ion-items
ion-item {
  --background-focused: unset;
}

// ion-toast
ion-toast {
  font-family: var(--font-semibold);
  --background: var(--alberta-color-primary);
  --color: var(--alberta-color-primary-contrast);
  --button-color: var(--alberta-color-primary-contrast);
}

.toast-text-select {
  user-select: text;
}

.toast-danger {
  --background: var(--alberta-color-danger);
  --color: white;
  --button-color: white;
}

.toast-warning {
  --background: var(--alberta-color-alert);
  --color: #2b2b2b;
  --button-color: #2b2b2b;
  --ion-color-step-100: #2b2b2b;
}

.toast-success {
  --background: var(--alberta-color-success);
  --color: #2b2b2b;
  --button-color: #2b2b2b;
  --ion-color-step-100: #2b2b2b;
}

// Line break in radio and checkbox options
.alert-checkbox-group,
.alert-radio-group {
  height: 242px;
  overflow: scroll;

  .alert-checkbox-button,
  .alert-radio-button {
    contain: unset;
    height: unset;

    .alert-button-inner,
    .alert-button-inner {
      .alert-checkbox-label,
      .alert-radio-label {
        text-overflow: unset;
        white-space: unset;
        font-size: 14px;
      }
    }
  }
}

/*
* ion-modal
* ======================================
*/

ion-modal {
  --width: 1000px;
  --max-width: 100%;
}

.selectSeriesOrderAction {
  --ion-select-modal: 455px;
}

.maintenanceInfoModal {
  --ion-select-modal: 300px;
}

.selectInstituteEditAction {
  --ion-select-modal: 355px;
}

ion-modal.selectModal {
  --width: calc(100% - 20px);
  --height: var(--ion-select-modal);
  --border-radius: 20px;

  @media only screen and (min-width: 360px) {
    --width: 360px;
  }
}

ion-modal.fullPageModal {
  --height: 100%;

  @media only screen and (min-height: 600px) {
    @supports (-webkit-hyphens: none) {
      --width: 100%;
    }
  }
}

.syncModal {
  --height: 100%;
  --width: 100%;
}

ion-modal.alert {
  --height: auto;
  --max-width: 280px;
  --border-radius: 4px;
}

ion-modal.auto-height {
  --border-radius: 20px;
  --height: auto;
  --width: 485px;

  @media only screen and (max-width: 500px) {
    --border-radius: 20px 20px 0 0;
    --width: 100%;
    align-items: end;
  }
}

ion-modal.loading-modal {
  --max-height: 300px;
  --max-width: 300px;
  --border-radius: 8px;
}

ion-modal.modal-default.show-modal ~ ion-modal.loading-modal {
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

//======================================

.ordereditpopover {
  .popover-content {
    left: 0px !important;
    bottom: 25px;
    top: unset !important;
  }

  @media only screen and (min-height: 600px) and (min-width: 1180px) {
    .popover-content {
      bottom: calc(50% - (700px / 2) + 52px);
      left: calc(50% - (1000px / 2)) !important;
      top: unset !important;
    }
  }

  .popover-content {
    transform-origin: left bottom 0px !important;
  }
}

.changeDocumentListPopover {
  .popover-content {
    right: 22px !important;
    top: 67px !important;
    left: unset !important;
  }
}

.sortPopover {
  .popover-content {
    right: 22px !important;
    top: 67px !important;
    left: unset !important;
    width: 350px;
  }
}

.overridePrimaryToVariant {
  --ion-color-primary: var(--alberta-color-secondary-variant);
}

.k-pager-numbers .k-state-selected {
  cursor: inherit;
  z-index: 2;
}

.k-link.k-state-selected::before {
  opacity: 0.2;
}

/**
  * Barcode Scanning
  * -------------------------------------------
  */
body.barcode-scanning-active {
  visibility: hidden;
  --background: transparent;
  --ion-background-color: transparent;
}

/* Set ion-header background color to white only for your component */
app-barcode-scanning ion-header {
  --background: white !important;
  --ion-background-color: white !important;
}

.barcode-scanning-modal {
  --height: 100%;
  --width: 100%;

  visibility: visible;
}

swiper-container {
  --swiper-pagination-bullet-inactive-color: var(--ion-color-step-200, #cccccc);
  --swiper-pagination-color: var(--ion-color-primary, #3880ff);
  --swiper-pagination-progressbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.25);
  --swiper-scrollbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.1);
  --swiper-scrollbar-drag-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.5);
}

swiper-slide {
  display: flex;
  position: relative;

  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  font-size: 18px;

  text-align: center;
  box-sizing: border-box;
}

swiper-slide img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}
